body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[aria-label="January 1, 2023"],
[aria-label="Janyary 22, 2023"],
[aria-label="February 18, 2023"],
[aria-label="March 22, 2023"],
[aria-label="April 7, 2023"],
[aria-label="April 22, 2023"],
[aria-label="April 23, 2023"],
[aria-label="May 1, 2023"],
[aria-label="May 18, 2023"],
[aria-label="June 1, 2023"],
[aria-label="June 4, 2023"],
[aria-label="June 29, 2023"],
[aria-label="July 19, 2023"],
[aria-label="August 17, 2023"],
[aria-label="September 28, 2023"],
[aria-label="December 25, 2023"] {
  color: rgba(255, 0, 0, 1) !important;
  -Webkit-text-fill-color: rgba(255, 0, 0, 1)!important;
  -Webkit-color: rgba(255, 0, 0, 1)!important;
  -Webkit-text-stroke-color: rgba(255, 0, 0, 1)!important;
}

[aria-label="1 Januari 2023"],
[aria-label="22 Januari 2023"],
[aria-label="18 Februari 2023"],
[aria-label="22 Maret 2023"],
[aria-label="7 April 2023"],
[aria-label="22 April 2023"],
[aria-label="23 April 2023"],
[aria-label="1 Mei 2023"],
[aria-label="18 Mei 2023"],
[aria-label="1 Juni 2023"],
[aria-label="4 Juni 2023"],
[aria-label="29 Juni 2023"],
[aria-label="19 Juli 2023"],
[aria-label="17 Agustus 2023"],
[aria-label="28 September 2023"],
[aria-label="25 Desember 2023"] {
  color: red !important;
}

#iRoot > div.content-wrapper > div > div:nth-child(1) > div > div.card-body > div > div:nth-child(1) > table > tr:nth-child(1) > td:nth-child(2) > div > div > div > select {
  appearance: none;
  margin-Right: -4px;
}

#iRoot > div.content-wrapper > div > div:nth-child(1) > div > div.card-body > div > div:nth-child(1) > table > tr:nth-child(2) > td:nth-child(2) > div > div > div > select {
  appearance: none;
  margin-Right: -4px;
}


#iRoot > div.content-wrapper > section > div:nth-child(1) > div > div.card-body > table > tr:nth-child(1) > td:nth-child(2) > div > div > div > select {
  appearance: none;
  margin-Right: -4px;
}

#iRoot > div.content-wrapper > section > div:nth-child(1) > div > div.card-body > table > tr:nth-child(1) > td:nth-child(4) > div > div > div > select {
  appearance: none;
  margin-Right: -4px;
}

#iRoot > div > div > div > div > div.card-body > div:nth-child(2) > div > div > div > select {
  appearance: none;
  margin-Right: -4px;
}

#iRoot > div > div > div > div > div.card-body > table > tr:nth-child(2) > td:nth-child(2) > div > div > div > select {
  appearance: none;
  margin-Right: -4px;
}


html body.layout-fixed.control-sidebar-slide-open.layout-navbar-fixed.layout-footer-fixed.text-sm div#iRoot.wrapper div.content-wrapper div.row div.col-md-12 div.card div.card-body div.row div.col-md-4 table tr td div.react-datetime-picker.react-datetime-picker--open.react-datetime-picker--enabled span div.react-datetime-picker__calendar.react-datetime-picker__calendar--open div.react-calendar div.react-calendar__viewContainer div.react-calendar__month-view div div div.react-calendar__month-view__days button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: blue;
}
